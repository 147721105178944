<template>
  <b-row class="align-items-center">
    <b-col
      cols="12"
      md="2"
    >
      <div class="font-small-3">
        {{ moment(dataItem.wdate).format('DD.MM.YYYY') }}
      </div>
      <div class="font-weight-bold text-danger">
        İş Emri No: {{ dataItem.work_no }}
      </div>
    </b-col>
    <b-col>
      <div class="font-small-3 text-warning">
        {{ dataItem.service_section }}
      </div>
      <div class="font-weight-bold">
        {{ dataItem.service_worker }}
      </div>
    </b-col>
    <b-col
      cols="12"
      md="3"
    >
      <div class="text-center">
        <template v-if="dataItem.working.status">
          <b-spinner
            v-if="!duration"
          />
          <template v-else>
            <div class="font-weight-bolder font-medium-2 text-danger">
              {{ duration }}
            </div>
            <div class="font-small-2 text-danger">
              Çalışıyor
            </div>
          </template>
        </template>
        <template v-else>
          <b-badge
            v-if="!dataItem.duration"
            variant="light-danger"
          >
            Beklemede
          </b-badge>
          <b-badge
            v-if="dataItem.duration"
            :variant="dataItem.workStatus? 'light-success' : 'light-secondary'"
          >
            {{ dataItem.workStatus? 'İşlem Tamamlandı' : 'İşlem Duraklatıldı' }}
          </b-badge>
        </template>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {
  BBadge, BCol, BRow, BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'LineItem',
  components: {
    BBadge, BRow, BCol, BSpinner,
  },
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      duration: null,
    }
  },
  mounted() {
    this.setDuration()
  },
  methods: {
    setDuration() {
      setInterval(() => {
        if (this.dataItem.working.status) {
          this.duration = this.moment.utc(this.moment.duration(this.moment().diff(this.dataItem.working.sdate)).asMilliseconds()).format('HH:mm:ss')
        } else {
          this.duration = null
        }
      }, 1000)
    },
  },
}
</script>
