<template>
  <b-card>
    <b-row>
      <b-col>
        <v-select
          v-model="id_com_location"
          :options="locations"
          label="title"
          :reduce="item => item.id"
          placeholder="Lokasyon"
        />
      </b-col>
      <b-col cols="auto">
        <div class="d-flex justify-content-between mb-1">
          <b-button
            variant="danger"
            to="/service/work-orders"
            class="mr-1"
          >
            <FeatherIcon icon="ListIcon" /> Tüm İş Emirleri
          </b-button>
          <b-button
            variant="primary"
            to="/service/work-orders/add"
          >
            <FeatherIcon icon="PlusIcon" /> Yeni İş Emri Ekle
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-list-group>
      <b-list-group-item
        v-for="item in dataList"
        :key="item.id"
        button
        :class="item.working.status? 'bg-light-success' : ''"
        @click="toWorkOrder(item.id)"
      >
        <line-item :data-item="item" />
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import {
  BCard, BButton, BBadge, BListGroup, BListGroupItem, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import LineItem from '@/views/Service_work_orders/Orders/OpenIndex/LineItem.vue'

const tableName = 'com_service_work_orders'
export default {
  name: 'Index',
  components: {
    BCard,
    BButton,
    BBadge,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    vSelect,
    LineItem,
  },
  data() {
    return {
      // userData: JSON.parse(localStorage.getItem('userData')),
      id_com_location: '1',
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.wdate AS wdate`,
          `${tableName}.work_no AS work_no`,
          `${tableName}.is_da AS is_da`,
          `${tableName}.is_repair AS is_repair`,
          `${tableName}.is_change AS is_change`,
          `${tableName}.is_work AS is_work`,
          'com_service_sections.title AS service_section',
          'com_service_workers.fullname AS service_worker',
        ],
        where: {
          'com_service_work_orders.id_com_location': '1',
        },
        duration: true,
        order_by: ['com_service_work_orders.id', 'DESC'],
      },
    }
  },
  computed: {
    userData() {
      const data = JSON.parse(localStorage.getItem('userData'))
      if (data) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dataQuery.where.id_com_location = data.id_com_location
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.id_com_location = data.id_com_location
      }
      return data
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    dataList() {
      return this.$store.getters['serviceWorkOrders/openDataList']
    },
  },
  watch: {
    id_com_location(val) {
      this.dataQuery.where['com_service_work_orders.id_com_location'] = val
      this.getDataList()
    },
  },
  created() {
    this.getLocations()
    this.getDataList()
  },
  methods: {
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        where: {
          'com_location.id !=': 4,
        },
      })
    },
    toWorkOrder(id) {
      this.$router.push(`/service/work-orders/worker-view/${id}`)
    },
    getDataList() {
      const data = JSON.parse(localStorage.getItem('userData'))
      if (data) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dataQuery.where['com_service_workers.id_com_location'] = data.id_com_location
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.id_com_location = data.id_com_location
      }
      this.$store.dispatch('serviceWorkOrders/getOpenDataList', this.dataQuery)
    },
  },
}
</script>
